.Button {
  padding: 0.2em 1.8em;
  font-weight: 300;
  color: #000000;
  transition: all 0.2s;
  font-weight: 500;
  font-size: 1rem;
}
.Button:hover {
  color: #299cfa;
  background-color: #ffffff;
}
