.App {
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

@import "react-datetime/css/react-datetime.css";
