.Wrapper {
  padding-top: 0.1em;
  background-color: rgb(27, 27, 27);
}

.Picker {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgb(27, 27, 27);
}

.Dist {
  color: azure;
  font-weight: 500;
  margin-left: 0.5em;
  font-size: 1rem;
}

.Pick {
  margin: 0.5em 0.1em 0.5em 0;
}

.Pick input {
  padding: 0.2em;
  text-align: center;
  max-width: 100vw;
  min-width: 20vw;
}

@media only screen and (max-width: 600px) {
  .Pick input {
    min-width: 100%;
  }
  .Pick {
    margin: 0;
  }

  .Picker {
    flex-flow: column;
    flex-wrap: nowrap;
  }
}
